@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	font-family: "Inter Tight", sans-serif;
	font-size: 16px;
	line-height: 1.5rem;
}

html,
body {
	@apply bg-white;
	@apply text-gray-800;
}

$sprite-height: 32px;
$sprite-width: 26px;

$teams: (
	"SEA",
	"NYJ",
	"CHI",
	"TB",
	"MIN",
	"DAL",
	"NE",
	"TEN",
	"NYG",
	"BAL",
	"BUF",
	"LV",
	"IND",
	"HOU",
	"JAC",
	"CLE",
	"CIN",
	"PIT",
	"MIA",
	"KC",
	"DEN",
	"LAC",
	"PHI",
	"WAS",
	"LAR",
	"SF",
	"ARI",
	"ATL",
	"CAR",
	"NO",
	"GB",
	"DET"
);

.team-sprite {
	background-image: url(../img/sprites-2x.png);
	height: $sprite-height;
	transform: scaleX(-1);
	width: $sprite-width;
}

.team-sprite[data-state="run"],
.team-sprite[data-state="sprint"],
.team-sprite[data-state="jog"] {
	animation-timing-function: steps(6);
	animation-iteration-count: infinite;
}

.team-sprite[data-state="sprint"] {
	animation-duration: 0.6s;
}

.team-sprite[data-state="run"] {
	animation-duration: 0.8s;
}

.team-sprite[data-state="jog"] {
	animation-duration: 1s;
}

.team-sprite[data-state="tripped"] {
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-timing-function: steps(5);
}

@each $team in $teams {
	// sass indexes start at 1 smh...
	$i: index($teams, $team) - 1;

	.team-sprite[data-team="#{$team}"][data-state="ready"] {
		background-position: #{$sprite-width * -7} #{$i * $sprite-height * -1};
	}

	.team-sprite[data-team="#{$team}"][data-state="stand"] {
		background-position: #{$sprite-width * -6} #{$i * $sprite-height * -1};
	}

	.team-sprite[data-team="#{$team}"][data-state="run"],
	.team-sprite[data-team="#{$team}"][data-state="sprint"],
	.team-sprite[data-team="#{$team}"][data-state="jog"] {
		animation-name: team-sprite-move-#{$team};
	}

	.team-sprite[data-team="#{$team}"][data-state="tripped"] {
		animation-name: team-sprite-trip-#{$team};
	}

	@keyframes team-sprite-move-#{$team} {
		from {
			background-position: 0px #{$i * $sprite-height * -1};
		}
		to {
			background-position: #{$sprite-width * -6} #{$i * $sprite-height * -1};
		}
	}

	@keyframes team-sprite-trip-#{$team} {
		from {
			background-position: #{$sprite-width * -8} #{$i * $sprite-height * -1};
		}
		to {
			background-position: #{$sprite-width * -13} #{$i * $sprite-height * -1};
		}
	}
}
